import React from 'react';
import getAppletByMetaPath from 'packages/firebase/firestore/get-applet-by-meta-path';
import getCache from 'packages/firebase/firestore/get-cache';
import Home from 'components/home';

const HomePage = function () {
  return <Home />;
};

HomePage.defaultProps = {
  showOptions: false,
};

export async function getStaticProps() {
  const staticProps = {
    props: {},
    revalidate: 1 * 24 * 60 * 60,
  };
  const ownerName = process.env.NEXT_PUBLIC_HOME_OWNERNAME || '';
  const appId = process.env.NEXT_PUBLIC_HOME_APP || '';
  if (!ownerName || !appId) {
    return staticProps;
  }
  const applet = await getAppletByMetaPath({ ownerName, appId, appletId: appId });
  const cache = applet && await getCache(applet.id);
  staticProps.props = { cache };
  return staticProps;
}

export default HomePage;
